import { Component, AfterViewInit, Input } from '@angular/core';
import { SubscriptionService } from '../services/subscription.service';

@Component({
  selector: 'app-subscribe-confirmed',
  templateUrl: './subscribe-confirmed.component.html',
  styleUrls: ['./subscribe-confirmed.component.css'],
})
export class SubscribeConfirmedComponent implements AfterViewInit {
  message = '';

  constructor(public subscriptionService: SubscriptionService) {}

  ngAfterViewInit(): void {
    this.subscriptionService.subscription.subscribe(sub => {
      this.message = sub?.getMessage?.() ?? '';
    });

    // Reddit pixel page visit tracking
    if (typeof (window as any).rdt === 'function') {
      (window as any).rdt('init', 't2_ay89s8t8');
      (window as any).rdt('track', 'PageVisit');
    }
  }
}
